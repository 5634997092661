import { InputNumber, Slider, Switch, Segmented } from 'antd';
import { useTranslation } from 'react-i18next';
import { themeStore, MenuTheme } from 'src/stores/theme';
import { PageModeEnum, ThemeEnum } from 'src/interface/theme';
import { MenuThemeOptions, PageModeThemeOptions } from '../../const';

export default function ThemeStyle() {
  const {
    // menuDrag,
    menuWidth,
    openKeys,
    showMenu,
    collapsed,
    showCollapsed,
    theme,
    fixedFooter,
    pageMode,
    happyMode,
    setMenuConfig,
  } = themeStore((state) => ({
    ...state.theme.menu,
    setMenuConfig: state.setMenuConfig,
  }));
  const { t } = useTranslation();

  const onChange = (value: Partial<MenuTheme>) => {
    setMenuConfig?.(value);
  };

  return (
    <div className="mt-[10px]">
      {/* <div className="flex justify-between items-center my-[10px]">
        <div>{t('main.setting.menu_drag')}</div>
        <Switch checked={menuDrag} onChange={(e) => onChange({ menuDrag: e })}></Switch>
      </div> */}
      <div className="flex justify-between items-center my-[10px]">
        <div>{t('main.setting.open_keys')}</div>
        <Switch checked={openKeys} onChange={(e) => onChange({ openKeys: e })}></Switch>
      </div>
      <div className="flex justify-between items-center my-[10px]">
        <div>{t('main.setting.show_menu')}</div>
        <Switch checked={showMenu} onChange={(e) => onChange({ showMenu: e })}></Switch>
      </div>
      <div className="flex justify-between items-center my-[10px]">
        <div>{t('main.setting.show_collapse')}</div>
        <Switch checked={showCollapsed} onChange={(e) => onChange({ showCollapsed: e })}></Switch>
      </div>
      <div className="flex justify-between items-center my-[10px]">
        <div>{t('main.setting.collapsed')}</div>
        <Switch checked={collapsed} onChange={(e) => onChange({ collapsed: e })}></Switch>
      </div>
      <div className="flex justify-between items-center my-[10px]">
        <div>{t('main.setting.menu_theme')}</div>
        <Segmented
          options={MenuThemeOptions.map((item) => ({ label: t(item.label), value: item.value }))}
          value={theme}
          onChange={(e) => onChange({ theme: e as ThemeEnum })}></Segmented>
      </div>
      <div className="flex justify-between items-center my-[10px]">
        <div>{t('main.setting.page_theme')}</div>
        <Segmented
          options={PageModeThemeOptions.map((item) => ({
            label: t(item.label),
            value: item.value,
          }))}
          value={pageMode}
          onChange={(e) => onChange({ pageMode: e as PageModeEnum })}></Segmented>
      </div>
      <div className="flex justify-between items-center my-[10px]">
        <div>{t('main.setting.fixed_footer')}</div>
        <Switch checked={fixedFooter} onChange={(e) => onChange({ fixedFooter: e })}></Switch>
      </div>
      <div className="flex justify-between items-center my-[10px]">
        <div>{t('main.setting.happy_mode')}</div>
        <Switch checked={happyMode} onChange={(e) => onChange({ happyMode: e })}></Switch>
      </div>
      <div className="flex justify-between items-center my-[10px]">
        <div>{t('main.setting.menu_width')}</div>
        <div className="flex justify-end items-center flex-none w-[60%]">
          <Slider
            min={200}
            max={400}
            step={10}
            onChange={(e) => onChange({ menuWidth: e })}
            value={typeof menuWidth === 'number' ? menuWidth : Number(menuWidth)}
            className="w-[60%] max-w-[100px] flex-none"
          />
          <InputNumber
            min={200}
            max={400}
            step={10}
            style={{ marginLeft: '16px' }}
            value={menuWidth}
            onChange={(e) => onChange({ menuWidth: Number(e) })}
          />
        </div>
      </div>
    </div>
  );
}
