import { Tooltip } from 'antd';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { LayoutMenuList } from '../../const';
import { prefixCls } from 'src/constants';
import { useThemeMenu } from 'src/hooks/theme';
import { LayoutTypeEnum } from 'src/interface/theme';
import './index.less';

export default function LayoutType() {
  const { layoutMode, setLatyoutMix, setLatyoutTop, setLatyoutSider } = useThemeMenu();
  const { t } = useTranslation();

  const layoutCls = prefixCls + '-setting-layout';

  const changeLayoutMode = (layout: LayoutTypeEnum) => {
    switch (layout) {
      case LayoutTypeEnum.Mix:
        setLatyoutMix();
        break;
      case LayoutTypeEnum.Top:
        setLatyoutTop();
        break;
      case LayoutTypeEnum.Sider:
        setLatyoutSider();
        break;
    }
  };

  return (
    <div className={layoutCls}>
      {LayoutMenuList.map((item) => {
        return (
          <Tooltip title={t(item.title)} key={item.title}>
            <div
              className={clsx(
                `${layoutCls}-item`,
                `${layoutCls}-item__${item.layout}`,
                layoutMode === item.layout && `${layoutCls}-action`,
              )}
              onClick={() => changeLayoutMode(item.layout)}></div>
          </Tooltip>
        );
      })}
    </div>
  );
}
