import { Divider } from 'antd';
import { useTranslation } from 'react-i18next';

interface IProps {
  children?: React.ReactNode;
  title?: string;
}

export default function Block(props: IProps) {
  const { title, children } = props;
  const { t } = useTranslation();

  return (
    <>
      {title && <div className="font-bold text-base">{title ? t(title) : ''}</div>}
      {children}
      <Divider />
    </>
  );
}
