import clsx from 'clsx';
import { Avatar as AntdAvatar, Dropdown } from 'antd';
import type { MenuProps } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AiOutlineLogout } from 'react-icons/ai';
import { PAGE_LOGIN } from 'src/constants';
import { userStore } from 'src/stores/user';

interface IProps {
  className?: string;
}

enum SystemTypeEnum {
  LOGOUT = 'LOGOUT',
}

export default function Avatar(props: IProps) {
  const { className } = props;
  const navigate = useNavigate();
  const { logout } = userStore();
  const userInfo = { nickname: '', avatar: '' };
  const { t } = useTranslation();

  async function systemLogout() {
    await logout();
    navigate(PAGE_LOGIN);
  }

  const items: MenuProps['items'] = [
    {
      key: SystemTypeEnum.LOGOUT,
      label: t('main.header.exit'),
      icon: <AiOutlineLogout />,
    },
  ];

  const handleMap = {
    [SystemTypeEnum.LOGOUT]: systemLogout,
  };

  return (
    <Dropdown
      className={className}
      placement="top"
      arrow
      menu={{
        items,
        selectable: true,
        onSelect: ({ key }: { key: string }) => {
          if (handleMap[key as SystemTypeEnum]) handleMap[key as SystemTypeEnum]();
        },
      }}>
      <div className={clsx(className, 'flex-none')}>
        <AntdAvatar src={userInfo?.avatar}></AntdAvatar>
        <div className="ml-2 text-sm max-w-[100px] truncate">
          {userInfo?.nickname ?? t('main.header.default_name')}
        </div>
      </div>
    </Dropdown>
  );
}
