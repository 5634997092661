import { useOutlet, useLocation } from 'react-router-dom';
import { SwitchTransition, CSSTransition } from 'react-transition-group';

export default function RouteElement() {
  const element = useOutlet();
  const location = useLocation();

  return (
    <SwitchTransition>
      <CSSTransition
        unmountOnExit={true}
        key={location.pathname}
        clsx={{
          enter: 'animate__animated animate__bounceInLeft',
          exit: 'animate__animated animate__bounceOutRight',
        }}
        timeout={{ enter: 300, exit: 300 }}>
        {element}
      </CSSTransition>
    </SwitchTransition>
  );
}
