import { IMenuDataItem, RouteObject } from 'src/interface';
import { t } from 'src/locales';
import { Icon } from 'src/components/Icons';

export function filterRoutes(
  routeTree: RouteObject[],
  allowedMenuItems: string[],
  parentPath = '',
) {
  return routeTree.reduce((filteredRoutes, route) => {
    const filteredRoute = { ...route };

    if (!route.children) {
      // 如果路由项没有子路由，检查它自己的路径是否在允许的菜单列表中
      const fullPath = (parentPath.endsWith('/') ? parentPath : `${parentPath}/`) + route.path;
      if (allowedMenuItems.includes(fullPath) || allowedMenuItems.includes(route.path)) {
        filteredRoutes.push(filteredRoute);
      }
    } else {
      // 如果路由项有子路由，递归过滤子路由，并传递父级路径
      filteredRoute.children = filterRoutes(route.children, allowedMenuItems, route.path);
      // 保留有子路由的路由项
      if (filteredRoute.children.length > 0) {
        filteredRoutes.push(filteredRoute);
      }
    }

    return filteredRoutes;
  }, [] as RouteObject[]);
}

export function preCondition(menus: IMenuDataItem[]): IMenuDataItem[] {
  return menus.map((menu) => {
    return {
      ...menu,
      name: menu.locale ? t(menu.locale) : menu.menuName,
      icon: menu.icon ? <Icon name={menu.icon as string} /> : '',
    };
  });
}

export function generateNavigate(route: RouteObject[]) {
  let defaultRoutePath = '';
  function getFirstPath(data: RouteObject) {
    defaultRoutePath += data.path.startsWith('/') ? data.path : `/${data.path}`;
    if (data.children && data.children.length > 0) {
      getFirstPath(data.children[0]);
    }
  }
  if (route[0]) getFirstPath(route[0]);
  return defaultRoutePath;
}
