import { ScreenEnum, ScreenTypeEnum } from 'src/interface/theme';

const screenMap = new Map<ScreenTypeEnum, number>();

screenMap.set(ScreenTypeEnum.XS, ScreenEnum.XS);
screenMap.set(ScreenTypeEnum.SM, ScreenEnum.SM);
screenMap.set(ScreenTypeEnum.MD, ScreenEnum.MD);
screenMap.set(ScreenTypeEnum.LG, ScreenEnum.LG);
screenMap.set(ScreenTypeEnum.XL, ScreenEnum.XL);
screenMap.set(ScreenTypeEnum.XXL, ScreenEnum.XXL);

export { screenMap };
