import { RouteObject } from 'src/interface/route';
import Login from 'src/pages/login';
import loadable from '@loadable/component';

const Error403 = loadable(() => import('src/pages/error/403'));
const Error404 = loadable(() => import('src/pages/error/404'));
const Error500 = loadable(() => import('src/pages/error/500'));

const basicRouteer: RouteObject[] = [
  {
    path: '/login',
    name: '登录页',
    data: {
      locale: 'login_page',
      notAuth: true,
    },
    element: <Login />,
  },
  {
    path: '/403',
    name: '403',
    data: {
      locale: 'error.not_found',
      notAuth: true,
    },
    element: <Error403 />,
  },
  {
    path: '/404',
    name: '404',
    data: {
      locale: 'error.not_found',
      notAuth: true,
    },
    element: <Error404 />,
  },
  {
    path: '/500',
    name: '500',
    data: {
      locale: 'error.server_error',
      notAuth: true,
    },
    element: <Error500 />,
  },
];

export default basicRouteer;
