import { create } from 'zustand';
import { login, getPersonalInfo, getRoutes, logout as logoutRequest } from 'src/api/clients';
import { ILoginReq, IPersonalInfoResp } from 'src/api/types/login';
import { setToken, removeToken } from 'src/utils/cookie';
import { permissionStore } from './permission';

interface IUserStore {
  loading?: boolean;
  userInfo?: IPersonalInfoResp;
  login: (data: ILoginReq) => Promise<boolean>;
  getUserInfo: () => void;
  logout: () => void;
}

export const userStore = create<IUserStore>((set, get) => ({
  login: async (data: ILoginReq) => {
    try {
      const result = await login(data);
      setToken(result);
      return true;
    } catch (err) {
      console.log(err);
      return false;
    } finally {
      set({ loading: false });
    }
  },
  getUserInfo: async () => {
    if (get().userInfo) {
      return get().userInfo;
    }
    set({ loading: true });
    try {
      const [userInfo, routes] = await Promise.all([getPersonalInfo(), getRoutes()]);
      set({ userInfo: userInfo });
      permissionStore.getState().setMenus(routes);
    } catch (err) {
      console.log(err);
    } finally {
      set({ loading: false });
    }
  },
  async logout() {
    await logoutRequest();
    removeToken();
    set({ userInfo: undefined });
  },
}));
