import { Switch } from 'antd';
import { useThemeDark } from 'src/hooks/theme';
import { HiSun, HiMoon } from 'react-icons/hi';

export default function DarkSwitch() {
  const { dark, traggerTheme } = useThemeDark();

  return (
    <Switch
      checked={dark}
      checkedChildren={<HiMoon size={18} />}
      unCheckedChildren={<HiSun size={18} />}
      onChange={() => traggerTheme()}
    />
  );
}
