import { create } from 'zustand';
import { LayoutTypeEnum, MenuModeEnum, ThemeEnum, PageModeEnum } from 'src/interface/theme';
import config from 'src/configs/setting.json';

export const _defaultConfig = {
  // 暗色风格
  dark: false,
  // 紧凑风格
  compack: false,
  pageTheme: {
    // 布局模式
    layoutMode: LayoutTypeEnum.Sider,
    // antd 主题配置
    theme: {
      // 颜色主题
      colorPrimary: '#1677FF',
      // 成功色
      colorSuccess: '#52C41A',
      // 警戒色
      colorWarning: '#FAAD14',
      // 错误色
      colorError: '#FF4d4f',
      // 文字大小
      fontSize: 14,
      fontSizeMax: 32,
      // 圆角
      borderRadius: 6,
      // 数字最大配置
      borderRadiusMax: 16,
      // 线性化
      wireframe: false,
    },
    root: {
      // 显示logo
      showLogo: true,
    },
    // 内容区域
    menu: {
      // 侧导拖拽
      menuDrag: false,
      // 侧导宽度
      menuWidth: 220,
      // 展开全部菜单
      openKeys: false,
      // 侧导模式
      mode: MenuModeEnum.Inline,
      // 显示侧导
      showMenu: true,
      // 是否折叠
      collapsed: false,
      // 显示折叠
      showCollapsed: true,
      // 侧导主题
      theme: ThemeEnum.Light,
      // 固定底部菜单
      fixedFooter: true,
      // 页面模式
      pageMode: PageModeEnum.Normal,
      // 开启快乐模式
      happyMode: false,
    },
    header: {
      // 是否固定
      fixed: false,
      // 是否显示
      show: true,
    },
  },
};

export const initStates = Object.assign({}, _defaultConfig, config);
export type AntdTheme = typeof initStates.pageTheme.theme;
export type MenuTheme = typeof initStates.pageTheme.menu;
export type HeaderTheme = typeof initStates.pageTheme.header;

interface IThemeStore {
  dark: boolean;
  compack: boolean;
  theme: typeof initStates.pageTheme;
  antdTheme: AntdTheme;
  setTheme?: (theme: Partial<AntdTheme>) => void;
  setDarkMode?: (dark: boolean) => void;
  setPageCompack?: (compack: boolean) => void;
  setLayoutMode?: (layoutMode: LayoutTypeEnum) => void;
  setMenuConfig?: (menu: Partial<MenuTheme>) => void;
  setHeaderConfig?: (header: Partial<HeaderTheme>) => void;
  resetTheme?: () => void;
  changeCollapsed?: (collapsed: boolean) => void;
}

export const themeStore = create<IThemeStore>((set, get) => ({
  theme: initStates.pageTheme,
  compack: initStates.compack,
  dark: initStates.dark,
  antdTheme: initStates.pageTheme?.theme,
  setTheme: (theme: Partial<AntdTheme>) => {
    const beseTheme = get().theme;
    set({ theme: { ...beseTheme, ...theme } });
  },
  setDarkMode: (dark: boolean) => set({ dark }),
  setPageCompack: (compack: boolean) => set({ compack }),
  setLayoutMode: (layoutMode: LayoutTypeEnum) => {
    const baseTheme = get().theme;
    set({ theme: { ...baseTheme, layoutMode } });
  },
  setMenuConfig: (menu: Partial<MenuTheme>) => {
    const baseTheme = get().theme;
    set({ theme: { ...baseTheme, menu: { ...baseTheme.menu, ...menu } } });
  },
  setHeaderConfig: (header: Partial<HeaderTheme>) => {
    const baseTheme = get().theme;
    set({ theme: { ...baseTheme, header: { ...baseTheme.header, ...header } } });
  },
  resetTheme: () => {
    set({ theme: initStates.pageTheme, compack: initStates.compack, dark: initStates.dark });
  },
  changeCollapsed: (collapsed: boolean) => {
    const baseTheme = get().theme;
    set({ theme: { ...baseTheme, menu: { ...baseTheme.menu, collapsed } } });
  },
}));
