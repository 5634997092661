import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { MenusKeys } from 'src/constants';
import { IMenuDataItem, LayoutTypeEnum, MenuModeEnum, MenuTypeEnum } from 'src/interface';
import { useLocalStorageState } from 'ahooks';
import { permissionStore } from 'src/stores/permission';
import { useThemeMenu } from './useThemeMenu';

export default function useMenuSelected() {
  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);
  const [openKeys, setOpenKeys] = useState<string[] | undefined>([]);
  const [menusKeys, setMenusKeys] = useLocalStorageState<string[]>(MenusKeys, { defaultValue: [] });
  const { mode, collapsed, layoutMode, openKeys: isDefaultOpenKeys } = useThemeMenu();
  const { menuInfo } = permissionStore((state) => ({
    menuInfo: state.menuInfo,
  }));
  const location = useLocation();

  const changeOpenKeys = useCallback(
    (openKeys: string[]) => {
      if (mode === MenuModeEnum.Horizontal || layoutMode === LayoutTypeEnum.Top || collapsed) {
        return setOpenKeys(undefined);
      }
      setOpenKeys(openKeys);
    },
    [collapsed, layoutMode, mode],
  );

  // 根据历史记录实现默认展开
  const checkKeys = useCallback(
    (cacheKeys: string[]) => {
      const existCache = cacheKeys && cacheKeys.length > 0;
      const currentKeys =
        existCache && cacheKeys.includes(location.pathname) ? cacheKeys : [location.pathname];
      setSelectedKeys(currentKeys);
      if (!existCache) {
        setMenusKeys([location.pathname]);
      }
      const generatePaths = [] as string[];
      currentKeys.forEach((path) => {
        const keys = path.split('/').filter(Boolean);
        let preIndex = 0;
        for (let i = 0; i < keys.length; i++) {
          generatePaths.push(`${preIndex === i ? '' : keys[preIndex]}/${keys[i]}`);
          preIndex = i;
        }
        return generatePaths;
      });
      changeOpenKeys(generatePaths);
    },
    [changeOpenKeys, location.pathname],
  );

  // 支持所有的菜单展开
  const setAllOpenKeys = useCallback(() => {
    const allOpenKeys = [] as string[];
    const next = (menuInfo: IMenuDataItem[]) => {
      if (!menuInfo.length) {
        return;
      }
      menuInfo.forEach((item) => {
        if (item.menuType === MenuTypeEnum.DIRE) {
          allOpenKeys.push(item.path);
          if (item.children) {
            next(item.children);
          }
        }
      });
    };
    next(menuInfo);
    changeOpenKeys(allOpenKeys);
  }, [menuInfo, changeOpenKeys]);

  useEffect(() => {
    if (isDefaultOpenKeys) {
      setAllOpenKeys();
      return;
    }
    checkKeys(menusKeys ?? []);
  }, [
    location.pathname,
    collapsed,
    layoutMode,
    mode,
    isDefaultOpenKeys,
    checkKeys,
    setAllOpenKeys,
  ]);

  function changeSelectedKey(selectedKeys: string[]) {
    setMenusKeys(selectedKeys);
    setSelectedKeys(selectedKeys);
  }

  return {
    selectedKeys,
    openKeys,
    changeOpenKeys,
    changeSelectedKey,
  };
}
