import Cookies from 'js-cookie';
import { CacheToken } from 'src/constants/storage';

interface CookieAttributes {
  expires?: number | Date | undefined;
  path?: string | undefined;
  domain?: string | undefined;
  secure?: boolean | undefined;
  sameSite?: 'strict' | 'Strict' | 'lax' | 'Lax' | 'none' | 'None' | undefined;
  [property: string]: any;
}

export const getCookie = (name: string) => {
  return Cookies?.get(name);
};

export const setCookie = (name: string, value: any, options?: CookieAttributes) => {
  Cookies?.set(name, `${value}`, { expires: 7, ...options });
};

export const removeCookie = (name: string, options?: CookieAttributes) => {
  Cookies?.remove(name, options);
};

export const getToken = () => {
  return getCookie(CacheToken);
};

export const setToken = (token: string) => {
  setCookie(CacheToken, token);
};

export const removeToken = () => {
  removeCookie(CacheToken);
};
