const perfix = 'SLOTH_ADMIN';

// 当前应用语言
export const appLanguage = `${perfix}_LANGUAGE`;

// 当前主题颜色
export const appTheme = `${perfix}_THEME`;

// 存储token
export const CacheToken = `${perfix}_TOKEN`;

// 存储选中与菜单状态
export const MenusKeys = `${perfix}_MENUS_KEYS`;
