import { useMemo } from 'react';
import { COLLAPSED_WIDTH } from 'src/constants';
import { themeStore } from 'src/stores/theme';
import { MenuModeEnum, LayoutTypeEnum } from 'src/interface/theme';
// 处理侧导主题
export function useThemeMenu() {
  const { menu, layoutMode, root, header, changeCollapsed, setLayoutMode, setMenuConfig } =
    themeStore((state) => ({
      menu: state.theme.menu,
      layoutMode: state.theme.layoutMode,
      root: state.theme.root,
      header: state.theme.header,
      changeCollapsed: state.changeCollapsed,
      setLayoutMode: state.setLayoutMode,
      setMenuConfig: state.setMenuConfig,
    }));

  const { fixed: headerFixed } = header;
  const {
    menuDrag,
    menuWidth,
    openKeys,
    mode,
    showMenu,
    collapsed,
    showCollapsed,
    // 侧导主题
    theme,
  } = menu;
  const { showLogo } = root;
  // 判断页面布局
  const isLayoutMix = useMemo(() => {
    return layoutMode === LayoutTypeEnum.Mix;
  }, [layoutMode]);

  const isLayoutTop = useMemo(() => {
    return layoutMode === LayoutTypeEnum.Top;
  }, [layoutMode]);

  const isLayoutSider = useMemo(() => {
    return layoutMode === LayoutTypeEnum.Sider;
  }, [layoutMode]);

  // 显示侧导
  const showSider = useMemo(() => {
    return showMenu && mode !== MenuModeEnum.Horizontal;
  }, [showMenu, mode]);

  // 显示顶部菜单
  const showTopMenu = useMemo(() => {
    return mode === MenuModeEnum.Horizontal;
  }, [mode]);

  // 获取当前侧导宽度
  const currentWidth = useMemo(() => {
    return collapsed ? COLLAPSED_WIDTH : menuWidth;
  }, [collapsed, menuWidth]);

  // 计算剩余宽度
  const remainingWidth = useMemo(() => {
    let width = 0;
    if (!showMenu || isLayoutTop || (!headerFixed && isLayoutSider)) {
      width = 0;
    } else {
      width = currentWidth;
    }
    return `calc(100% - ${width}px)`;
  }, [showMenu, isLayoutTop, currentWidth, isLayoutSider, headerFixed]);

  // 切换菜单收起与显示
  const traggetCollapsed = (flag?: boolean) => {
    changeCollapsed?.(flag !== undefined ? flag : !collapsed);
  };

  const setLatyoutTop = () => {
    if (isLayoutTop) return;
    setLayoutMode?.(LayoutTypeEnum.Top);
    setMenuConfig?.({ mode: MenuModeEnum.Horizontal });
    traggetCollapsed(false);
  };

  const setLatyoutSider = () => {
    if (isLayoutSider) return;
    setLayoutMode?.(LayoutTypeEnum.Sider);
    setMenuConfig?.({ mode: MenuModeEnum.Inline });
    traggetCollapsed(false);
  };

  const setLatyoutMix = () => {
    if (isLayoutMix) return;
    setLayoutMode?.(LayoutTypeEnum.Mix);
    setMenuConfig?.({ mode: MenuModeEnum.Inline });
    traggetCollapsed(false);
  };
  return {
    mode,
    showSider,
    showTopMenu,
    showLogo,
    collapsed,
    showCollapsed,
    layoutMode,
    menuTheme: theme,
    menuDrag,
    menuWidth,
    currentWidth,
    openKeys,
    remainingWidth,
    isLayoutMix,
    isLayoutTop,
    isLayoutSider,
    traggetCollapsed,
    setLatyoutTop,
    setLatyoutSider,
    setLatyoutMix,
  };
}
