import { useEffect, useRef } from 'react';
import { t } from 'src/locales';
import { PAGE_NOT_FOUND, PAGE_NOT_PERMISSION, PAGE_DEFAULT, PAGE_HOME } from 'src/constants';
import { useLocation, useNavigate, matchRoutes } from 'react-router-dom';
import { getToken } from 'src/utils/cookie';
import { userStore } from 'src/stores/user';
import { permissionStore } from 'src/stores/permission';
import { routeConfig } from 'src/router';
import { App } from 'antd';
interface IProps {
  children: React.ReactNode;
}

export default function RouterGuard(props: IProps) {
  const { userInfo, getUserInfo, loading } = userStore();
  const location = useLocation();
  const navigate = useNavigate();
  const {
    menus: permissionMenus,
    dynamicRoute,
    defaultOpenPath: defaultRoutePath,
  } = permissionStore();
  const pathname = useRef<string>();
  const token = getToken();
  const { message } = App.useApp();
  // 请求用户信息
  const requestUserInfo = () => {
    // 获取数据是异步操作，需要标识位 防止重复调用
    if (!userInfo?.userId && !loading) {
      getUserInfo();
    }
  };
  // 登录认证
  const outerAuthHandle = () => {
    pathname.current = location.pathname;
    if (token) {
      if (location.pathname === '/login') {
        navigate('/');
        return false;
      }
      if (!userInfo?.userId) {
        requestUserInfo();
        return true;
      }
    }
    if (!token && location.pathname !== '/login') {
      message.error(t('login.expire'));
      navigate(`/login?redirect=${pathname.current}`);
      return false;
    }
    return true;
  };

  // 路由认证
  const authentication = () => {
    if (dynamicRoute === null) return;
    if ([PAGE_DEFAULT, PAGE_HOME].includes(location.pathname)) {
      return navigate(defaultRoutePath);
    }
    const baseRoute = [...dynamicRoute, ...routeConfig];
    const matchs = matchRoutes(baseRoute, location.pathname) ?? [];
    const searchRoute = matchs.find((item) => item?.pathnameBase === location.pathname);

    // 如果没有匹配到路由 则跳转到404
    if (!matchs?.length || !searchRoute) {
      message.error(t('error.not_found'));
      navigate(PAGE_NOT_FOUND);
      return;
    }
    // 没在权限列表，并且是需要进行认证（notAuth = false）
    if (
      !permissionMenus.includes(searchRoute!.pathnameBase) &&
      !searchRoute?.route?.data?.notAuth
    ) {
      message.error(t('error.no_permission'));
      navigate(PAGE_NOT_PERMISSION);
      return;
    }
  };

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    outerAuthHandle() && authentication();
  }, [location.pathname, dynamicRoute]);

  return props.children;
}
