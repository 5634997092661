import loadable from '@loadable/component';
import { RouteObject } from 'src/interface/route';
import Layout from 'src/layout';

const Databoard = loadable(() => import('src/pages/dashboard/databoard'));
const Workplace = loadable(() => import('src/pages/dashboard/workplace'));

const dashboardRoute: RouteObject[] = [
  {
    path: '/dashboard',
    element: <Layout />,
    name: '仪表盘',
    data: {
      locale: 'menu.dashboard',
    },
    children: [
      {
        path: 'databoard',
        name: '数据看板',
        data: {
          locale: 'menu.databoard',
        },
        element: <Databoard />,
      },
      {
        path: 'workplace',
        name: '工作台',
        data: {
          locale: 'menu.workplace',
        },
        element: <Workplace />,
      },
    ],
  },
];

export default dashboardRoute;
