import { Popover, Badge, Tabs } from 'antd';
import type { TabsProps } from 'antd';
import clsx from 'clsx';
import { AiOutlineBell } from 'react-icons/ai';

interface IProps {
  className: string;
}

export default function Notice(props: IProps) {
  const { className } = props;
  const onChange = (key: string) => {
    console.log(key);
  };

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: '消息（3）',
      children: 'Content of Tab Pane 1',
    },
    {
      key: '2',
      label: '通知（3）',
      children: 'Content of Tab Pane 2',
    },
    {
      key: '3',
      label: '代办（2）',
      children: 'Content of Tab Pane 3',
    },
  ];
  return (
    <Popover content={<Tabs animated defaultActiveKey="1" items={items} onChange={onChange} />}>
      <div className={clsx(className, 'relative')}>
        <Badge size="small" count={8}>
          <AiOutlineBell size={20} />
        </Badge>
      </div>
    </Popover>
  );
}
