import { Form, Input, Button, Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import { AiFillLock, AiOutlineUser } from 'react-icons/ai';
import { MdOutlineMailOutline, MdQrCode } from 'react-icons/md';

export default function SignUpForm() {
  const { t } = useTranslation();

  const getCode = () => {
    console.log(111);
  };

  return (
    <Form size={'large'} initialValues={{ remember: true }} className="mt-4">
      <Form.Item name="username" rules={[{ required: true, message: t('login.user_placeholder') }]}>
        <Input prefix={<AiOutlineUser />} placeholder={t('login.user_placeholder')} />
      </Form.Item>
      <Form.Item name="password" rules={[{ required: true, message: t('login.pass_placeholder') }]}>
        <Input prefix={<AiFillLock />} type="password" placeholder={t('login.pass_placeholder')} />
      </Form.Item>
      <Form.Item rules={[{ required: true, message: t(`login.pass_placeholder`) }]}>
        <Input prefix={<MdOutlineMailOutline />} placeholder={t(`login.email_placeholder`)} />
      </Form.Item>
      <Form.Item>
        <Row gutter={12}>
          <Col span={15}>
            <Form.Item
              name="code"
              rules={[{ required: true, message: t('login.code_placeholder') }]}
              noStyle>
              <Input
                prefix={<MdQrCode />}
                type="password"
                placeholder={t('login.code_placeholder')}
              />
            </Form.Item>
          </Col>
          <Col span={9}>
            <Button block onClick={getCode}>
              {t('login.get_code')}
            </Button>
          </Col>
        </Row>
      </Form.Item>
      <Form.Item>
        <Button type="primary" block size="large" htmlType="submit" className="login-form-button">
          {t('login.signup')}
        </Button>
      </Form.Item>
    </Form>
  );
}
