import { useMemo } from 'react';
import { Layout } from 'antd';
import { AiOutlineMenuFold, AiOutlineMenuUnfold } from 'react-icons/ai';
import { prefixCls } from 'src/constants';
import clsx from 'clsx';
import { useThemeHeader, useThemeMenu } from 'src/hooks/theme';
import Language from 'src/components/Language';
import LogoIcon from '../LogoIcon';
import Menus from '../Menus';
import Breadcrumb from './components/Breadcrumb';
import ScreenFull from './components/ScreenFull';
import Notice from './components/Notice';
import Avatar from './components/Avatar';
import Setting from './components/Setting';
import './index.less';

const Header = Layout.Header;

interface IProp {
  isMobile?: boolean;
  fixed?: boolean;
}

export default function LayoutHeader(props: IProp) {
  const { isMobile, fixed } = props;
  const { showLogoHeader } = useThemeHeader();
  const {
    isLayoutMix,
    menuWidth,
    collapsed,
    traggetCollapsed,
    isLayoutTop,
    showCollapsed,
    showTopMenu,
  } = useThemeMenu();
  const header = `${prefixCls}-layout-header`;

  const actionCls =
    'flex justify-center items-center px-[12px] cursor-pointer hover:bg-gray-100 hover:dark:bg-gray-800 h-[100%]';

  const computedCls = useMemo(() => {
    return clsx({
      [`${header}__fixed`]: fixed,
      [`${header}__mobile`]: isMobile,
    });
  }, [fixed, isMobile, header]);

  const logoStyle = useMemo(() => {
    if (isMobile) return {};
    const width = menuWidth < 185 ? 185 : menuWidth;
    return { width, flex: 'none' };
  }, [isMobile, menuWidth]);

  const renderLogo = useMemo(() => {
    if (showLogoHeader || isMobile) {
      const showTitle = !isMobile;
      return (
        <div style={logoStyle}>
          <LogoIcon isShowText={showTitle} />
        </div>
      );
    }
    return null;
  }, [showLogoHeader, isMobile, logoStyle]);

  const renderCollapsed = useMemo(() => {
    if (!showCollapsed || (isLayoutTop && !isMobile)) return null;
    return (
      <div
        className="flex justify-center items-center px-[12px] cursor-pointer hover:bg-gray-100 hover:dark:bg-gray-800"
        onClick={() => traggetCollapsed()}>
        {collapsed ? <AiOutlineMenuUnfold size={20} /> : <AiOutlineMenuFold size={20} />}
      </div>
    );
  }, [showCollapsed, isLayoutTop, collapsed, traggetCollapsed]);

  const renderMenus = useMemo(() => {
    if (!showTopMenu || isMobile) return null;
    return <Menus />;
  }, [showTopMenu, isMobile]);

  return (
    <Header className={clsx(computedCls, 'shadow-md', isLayoutMix && 'z-[400]')}>
      <div className="flex h-[100%] w-[100%]">
        {renderLogo}
        {renderCollapsed}
        <div className="flex-none flex items-center text-xl">
          <Breadcrumb isMobile={isMobile} />
        </div>
        <div className="px-4 w-[100%]">{renderMenus}</div>
        <div className={clsx(`${header}-action`)}>
          <Notice className={actionCls} />
          <ScreenFull className={actionCls} />
          <Language size={42} className={clsx(actionCls, 'flex-none')} />
          <Setting className={actionCls} />
          <Avatar className={actionCls} />
        </div>
      </div>
    </Header>
  );
}
