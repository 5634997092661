import { useEffect } from 'react';
import { themeStore } from 'src/stores/theme';
import { ThemeEnum } from 'src/interface/theme';

// 设置亮暗色主题
export default function useThemeDark() {
  const { dark, setDarkMode } = themeStore((state) => ({
    dark: state.dark,
    setDarkMode: state.setDarkMode,
  }));
  const traggerTheme = () => {
    setDarkMode?.(!dark);
  };

  useEffect(() => {
    const htmlAttr = document.documentElement.getAttribute('sloth-theme');
    const currentTheme = dark ? ThemeEnum.Dark : ThemeEnum.Light;
    if (htmlAttr !== currentTheme || !htmlAttr) {
      document.documentElement.setAttribute('sloth-theme', currentTheme);
    }
  }, [dark]);

  return {
    dark,
    traggerTheme,
  };
}
