import { useMemo } from 'react';
import clsx from 'clsx';
import { HEAD_HEIGHT, TAG_HEIGHT, prefixCls } from 'src/constants';
import { useThemeHeader } from 'src/hooks/theme';
import Header from '../Header';
import TabWrap from './components/TabWrap';
import './index.less';

interface IProps {
  isMobile?: boolean;
  remainingWidth?: string;
}

export default function LayoutHeaderTabs(props: IProps) {
  const { isMobile, remainingWidth } = props;
  const { showOuterHeader, showInsetHeader, fixed, show } = useThemeHeader();

  const fillComputedStyle = useMemo(() => {
    let height = HEAD_HEIGHT;
    if (show && fixed) {
      height += TAG_HEIGHT;
    }
    return { height, flex: 'none' };
  }, [show, fixed]);

  const computedCls = useMemo(() => {
    const headerTopCls = prefixCls + '-layout-header-top';
    return clsx({
      [`${headerTopCls}`]: true,
      [`${headerTopCls}__fixed`]: fixed,
    });
  }, [fixed]);

  const wrapperStyle = useMemo(() => {
    const style: React.CSSProperties = {};
    if (fixed) {
      style.width = isMobile ? '100%' : remainingWidth;
    }
    if (showOuterHeader) {
      style.top = HEAD_HEIGHT + 'px';
    }
    return style;
  }, [isMobile, remainingWidth, showOuterHeader, fixed]);

  return (
    <>
      {(fixed || showOuterHeader) && <div style={fillComputedStyle}></div>}
      <div id="HeaderTop" style={wrapperStyle} className={computedCls}>
        {showInsetHeader && <Header isMobile={isMobile} />}
        <div className={computedCls + '-tabs'} style={{ height: TAG_HEIGHT }}>
          <TabWrap />
        </div>
      </div>
    </>
  );
}
