import { LayoutTypeEnum, ThemeEnum, PageModeEnum } from 'src/interface/theme';

export const LayoutMenuList = [
  {
    title: 'main.setting.layout_left',
    layout: LayoutTypeEnum.Sider,
  },
  {
    title: 'main.setting.layout_top',
    layout: LayoutTypeEnum.Top,
  },
  {
    title: 'main.setting.layout_mix',
    layout: LayoutTypeEnum.Mix,
  },
];

export const MenuThemeOptions = [
  {
    label: 'main.setting.menu_theme_config.light',
    value: ThemeEnum.Light,
  },
  {
    label: 'main.setting.menu_theme_config.dark',
    value: ThemeEnum.Dark,
  },
];

export const PageModeThemeOptions = [
  {
    label: 'main.setting.page_theme_config.normal',
    value: PageModeEnum.Normal,
  },
  {
    label: 'main.setting.page_theme_config.weakness',
    value: PageModeEnum.Weakness,
  },
  {
    label: 'main.setting.page_theme_config.grieve',
    value: PageModeEnum.Grieve,
  },
];
