import { useEffect, useMemo, useState } from 'react';
import { ConfigProvider, theme as antdTheme, MappingAlgorithm, App as AntdApp } from 'antd';
import { useTranslation } from 'react-i18next';
import { HappyProvider } from '@ant-design/happy-work-theme';
import { BrowserRouter, useRoutes } from 'react-router-dom';
import zh_CN from 'antd/lib/locale/zh_CN';
import en_US from 'antd/lib/locale/en_US';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import ErrorBoundary from 'src/components/ErrorBroundary';
import { routeConfig } from 'src/router';
import { prefixCls, PageModeStyleFilter } from 'src/constants';
import RouterGuard from 'src/router/RouterGuard';
import { LanguageEnum } from 'src/locales';
import { useThemeDark } from 'src/hooks/theme';
import { ScreenProvider } from 'src/context/ScreenContext';
import { getScreenSizeType } from 'src/utils/screen';
import { notification } from 'antd';
import { Updater } from 'src/utils/auto-update';
import { themeStore } from 'src/stores/theme';
import { permissionStore } from 'src/stores/permission';

import 'dayjs/locale/zh-cn';
import 'dayjs/locale/en-au';
dayjs.locale('zh-cn');
dayjs.extend(duration);

const up = new Updater({
  time: 1000 * 60 * 2,
});

const basename = import.meta.env?.PUBLIC_BASE_PATH ?? '/';

function Routes() {
  const { dynamicRoute } = permissionStore((state) => ({
    dynamicRoute: state.dynamicRoute,
  }));
  const routes = useRoutes([...(dynamicRoute ?? []), ...routeConfig]);
  return routes;
}

function App() {
  const { theme, menu, compack } = themeStore((state) => ({
    theme: state.theme.theme,
    menu: state.theme.menu,
    compack: state.compack,
  }));
  const { dark } = useThemeDark();
  const { i18n } = useTranslation();
  const [lang, setLang] = useState<string>(i18n.language);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    if (document.body) {
      document.body.style.filter = PageModeStyleFilter[menu.pageMode];
    }
  }, [menu.pageMode]);

  useEffect(() => {
    up.once('update', () => {
      notification.info({
        message: '最新版可用了！',
        description: '网站已发布新的版本，建议您完成手头工作后，立即刷新页面以启用最新版。',
        closable: false,
      });
    });
  }, []);

  // 设置主题算法
  const algorithmConf = useMemo(() => {
    const algorithm = [] as MappingAlgorithm[];
    algorithm.push(dark ? antdTheme.darkAlgorithm : antdTheme.defaultAlgorithm);
    if (compack) {
      algorithm.push(antdTheme.compactAlgorithm);
    }
    return algorithm.length > 1 ? algorithm : algorithm[0];
  }, [dark, compack]);

  // 设置语言
  const locale = useMemo(() => {
    return lang === LanguageEnum.CN ? zh_CN : en_US;
  }, [lang]);

  const applyScreenSize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    setLang(i18n.language);
    dayjs.locale(i18n.language === LanguageEnum.CN ? 'zh-cn' : 'en-au');
  }, [i18n.language]);

  useEffect(() => {
    window.addEventListener('resize', applyScreenSize);
    return () => {
      window.removeEventListener('resize', applyScreenSize);
    };
  }, []);

  return (
    <BrowserRouter basename={basename}>
      <ScreenProvider value={{ type: getScreenSizeType(windowWidth), size: windowWidth }}>
        <ConfigProvider
          theme={{
            algorithm: algorithmConf,
            token: theme,
          }}
          prefixCls={prefixCls}
          locale={locale}>
          <HappyProvider disabled={!menu.happyMode}>
            <AntdApp message={{ maxCount: 1 }} notification={{ top: 80 }}>
              <ErrorBoundary>
                <RouterGuard>
                  <Routes />
                </RouterGuard>
              </ErrorBoundary>
            </AntdApp>
          </HappyProvider>
        </ConfigProvider>
      </ScreenProvider>
    </BrowserRouter>
  );
}

export default App;
