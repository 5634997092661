import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import type { DragEndEvent } from '@dnd-kit/core';
import { DndContext, PointerSensor, useSensor } from '@dnd-kit/core';
import {
  arrayMove,
  horizontalListSortingStrategy,
  SortableContext,
  useSortable,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import { Dropdown, Button, Tabs as AntdTabs, MenuProps } from 'antd';
import { IoMdArrowDropdown } from 'react-icons/io';
import { useTranslation } from 'react-i18next';
import { cloneDeep } from 'lodash-es';
import { tabStore, permissionStore } from 'src/stores';
import { TabsActionEnum } from 'src/interface/theme';
import { prefixCls } from 'src/constants';
import './index.less';

interface DraggableTabPaneProps extends React.HTMLAttributes<HTMLDivElement> {
  'data-node-key': string;
}

const DraggableTabNode = ({ ...props }: DraggableTabPaneProps) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: props['data-node-key'],
  });

  const style: React.CSSProperties = {
    ...props.style,
    transform: CSS.Transform.toString(transform && { ...transform, scaleX: 1 }),
    transition: transition ? `transform 150ms ease-out, opacity 150ms ease-out` : undefined,
    cursor: 'move',
    opacity: transform ? 0.7 : 1, // 降低拖拽时的透明度
    boxShadow: transform ? '0 4px 8px rgba(0, 0, 0, 0.1)' : 'none', // 添加拖拽时的阴影效果
    zIndex: transform ? 5000 : undefined, // 设置拖拽时的 z-index，确保在顶层显示
    position: 'relative',
  };

  return React.cloneElement(props.children as React.ReactElement, {
    ref: setNodeRef,
    style,
    ...attributes,
    ...listeners,
  });
};

export default function TabWrap() {
  const location = useLocation();
  const navigate = useNavigate();

  const { tabList, setTabs, removeTabs } = tabStore((state) => ({ ...state }));
  const { defaultOpenPath } = permissionStore((state) => ({
    defaultOpenPath: state.defaultOpenPath,
  }));
  const { t } = useTranslation();

  const tabCls = prefixCls + '-layout-tabs';

  const onClose = (path: string) => {
    if (path === location.pathname) {
      const index = tabList.findIndex((item) => item.path === path);
      const preTab = tabList[index - 1];
      const finalPath = preTab ? preTab.path : defaultOpenPath;
      navigate(finalPath);
    }
    removeTabs(path);
  };

  const changeTabsHandle = (activeKey: string) => {
    if (activeKey === location.pathname) return;
    navigate(activeKey);
  };

  const editTabsHandle = (e: any, action: 'remove' | 'add') => {
    if (action === 'remove') {
      onClose(e);
    }
  };

  const closeAll = () => {
    setTabs([]);
    navigate(defaultOpenPath);
  };

  const closeOther = () => {
    const tabs = cloneDeep(tabList);
    const activeTab = tabs.filter((item) => item.path === location.pathname);
    setTabs(activeTab);
  };

  const closeLeft = () => {
    const tabs = cloneDeep(tabList);
    const activeIndex = tabs.findIndex((item) => item.path === location.pathname);
    tabs.splice(0, activeIndex);
    setTabs(tabs);
  };

  const closeRight = () => {
    const tabs = cloneDeep(tabList);
    const activeIndex = tabs.findIndex((item) => item.path === location.pathname);
    tabs.splice(activeIndex + 1, tabs.length);
    setTabs(tabs);
  };

  const onClickDropDownMenu: MenuProps['onClick'] = ({ key }) => {
    if (!tabList?.length) return;
    switch (Number(key)) {
      case TabsActionEnum.CloseAll: {
        closeAll();
        break;
      }
      case TabsActionEnum.CloseOther: {
        closeOther();
        break;
      }
      case TabsActionEnum.CloseLeft: {
        closeLeft();
        break;
      }
      case TabsActionEnum.CloseRight: {
        closeRight();
        break;
      }
      case TabsActionEnum.CloseCurrent: {
        onClose(location.pathname);
        break;
      }
    }
  };

  const sensor = useSensor(PointerSensor, { activationConstraint: { distance: 10 } });

  const onDragEnd = ({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      const currentIndex = tabList.findIndex((i) => i.path === active.id);
      const targetIndex = tabList.findIndex((i) => i.path === over?.id);
      setTabs(arrayMove(tabList, currentIndex, targetIndex));
    }
  };

  return (
    <AntdTabs
      hideAdd
      type="editable-card"
      className={tabCls}
      activeKey={location.pathname}
      onEdit={editTabsHandle}
      onChange={changeTabsHandle}
      items={[
        { label: '首页', key: defaultOpenPath, closeIcon: false },
        ...tabList.map((item) => ({
          key: item.path,
          label: item.locale ? t(item.locale) : item.name,
        })),
      ]}
      renderTabBar={(props, DefaultComp) => {
        return (
          <DndContext sensors={[sensor]} onDragEnd={onDragEnd}>
            <SortableContext
              items={tabList.map((i) => i.path)}
              strategy={horizontalListSortingStrategy}>
              <DefaultComp className="px-[10px]" {...props}>
                {(node) => (
                  <DraggableTabNode {...node.props} key={node.key}>
                    {node}
                  </DraggableTabNode>
                )}
              </DefaultComp>
            </SortableContext>
          </DndContext>
        );
      }}
      tabBarExtraContent={{
        right: (
          <Dropdown
            arrow
            menu={{
              items: [
                { label: t('main.header.tabs.close_all'), key: TabsActionEnum.CloseAll },
                { label: t('main.header.tabs.close_other'), key: TabsActionEnum.CloseOther },
                { label: t('main.header.tabs.close_left'), key: TabsActionEnum.CloseLeft },
                { label: t('main.header.tabs.close_right'), key: TabsActionEnum.CloseRight },
                { label: t('main.header.tabs.close_current'), key: TabsActionEnum.CloseCurrent },
              ],
              onClick: onClickDropDownMenu,
            }}>
            <Button className="h-[30px] relative z-auto p-[4px]">
              <IoMdArrowDropdown size={20} />
            </Button>
          </Dropdown>
        ),
      }}></AntdTabs>
  );
}
