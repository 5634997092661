import { create } from 'zustand';
import { IMenuDataItem, RouteObject, MenuTypeEnum } from 'src/interface';
import { generateMenuTree } from 'src/utils/tools';
import { preCondition, generateNavigate, filterRoutes } from './tools';
import { permissionRoute } from 'src/router';

interface IPermissionState {
  // 权限菜单列表
  menus: string[];
  // 权限按钮列表
  buttons: string[];
  // 菜单列表
  menuInfo: IMenuDataItem[];
  // 动态路由
  dynamicRoute: RouteObject[] | null;
  // 默认打开路径
  defaultOpenPath: string;
  setMenus: (menus: IMenuDataItem[]) => void;
}

export const permissionStore = create<IPermissionState>((set) => ({
  menus: [],
  buttons: [],
  menuInfo: [],
  dynamicRoute: null,
  defaultOpenPath: '/',
  setMenus: (menuList: IMenuDataItem[]) => {
    const menuAuthList = [] as string[];
    const btnAuthList = [] as string[];
    const filterMenuList = [] as IMenuDataItem[];
    menuList?.forEach((menu) => {
      if (menu.menuType === MenuTypeEnum.BUTTON) {
        if (menu.permission) btnAuthList.push(menu.permission);
        return;
      }
      if (menu.menuType === MenuTypeEnum.MENU) {
        if (menu.path) menuAuthList.push(menu.path);
      }
      filterMenuList.push(menu);
    });
    const routes = filterRoutes([...permissionRoute], [...menuAuthList]);
    set({
      buttons: btnAuthList,
      menus: menuAuthList,
      menuInfo: [...generateMenuTree(preCondition(filterMenuList))],
      dynamicRoute: routes,
      defaultOpenPath: generateNavigate(routes),
    });
  },
}));
