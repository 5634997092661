import { Drawer, DrawerProps, Button, Alert, App } from 'antd';
import { useTranslation } from 'react-i18next';
import { AiOutlineClose } from 'react-icons/ai';
import copy from 'copy-to-clipboard';
import { themeStore } from 'src/stores';
import DarkSwitch from 'src/components/DarkSwitch';
import Block from './components/Block';
import Layout from './components/Layout';
import ThemeStyle from './components/ThemeStyle';
import PageStyle from './components/PageStyle';

interface IProps extends DrawerProps {
  open: boolean;
}

export default function SettingDrawer(props: IProps) {
  const { t } = useTranslation();
  const { message, modal } = App.useApp();
  const {
    dark,
    compack,
    theme: pageTheme,
    resetTheme,
  } = themeStore((state) => ({
    theme: state.theme,
    dark: state.dark,
    compack: state.compack,
    resetTheme: state.resetTheme,
  }));

  const copyConfig = () => {
    copy(JSON.stringify({ dark, compack, pageTheme }, undefined, 4));
    message.success(t('main.setting.copy_success'));
  };

  const resetConfig = () => {
    modal.confirm({
      title: t('main.setting.reset_title'),
      content: t('main.setting.reset_descript'),
      onOk: () => {
        resetTheme?.();
      },
    });
  };

  return (
    <Drawer
      {...props}
      className="relative"
      closeIcon={null}
      title={t('main.setting.theme_title')}
      classNames={{ body: 'py-[]' }}
      extra={
        <AiOutlineClose
          onClick={(e) => props.onClose && props.onClose(e)}
          className="text-gray-600 hover:text-gray-950 dark:text-gray-400 dark:hover:text-gray-200 cursor-pointer"
        />
      }
      footer={
        <>
          <Button type="primary" className="mr-2" onClick={() => copyConfig()}>
            {t('main.setting.copy_button')}
          </Button>
          <Button className="mr-2" onClick={(e) => props.onClose && props.onClose(e)}>
            {t('main.setting.close_button')}
          </Button>
          <Button danger type="default" onClick={() => resetConfig()}>
            {t('main.setting.reset_button')}
          </Button>
        </>
      }>
      <Block>
        <div className="flex justify-between items-center">
          <div className="font-bold text-base">{t('main.setting.page_mode')}</div>
          <DarkSwitch />
        </div>
      </Block>
      <Block title="main.setting.nav_mode">
        <Layout />
      </Block>
      <Block title="main.setting.theme_style">
        <ThemeStyle />
      </Block>
      <Block title="main.setting.page_style">
        <PageStyle />
      </Block>
      <Alert type="info" showIcon message={t('main.setting.message')} />
    </Drawer>
  );
}
