import { useCallback, useEffect, useState } from 'react';
import { Breadcrumb as AntdBreadcrumb } from 'antd';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { useThemeMenu } from 'src/hooks/theme';
import { permissionStore } from 'src/stores/permission';
import { IMenuDataItem } from 'src/interface';

interface IProps {
  isMobile?: boolean;
}

interface IMenuItems {
  label: React.ReactNode;
  key: string;
  children: IMenuItems[];
}

interface IBreadcrumbItem {
  href?: string;
  title?: React.ReactNode;
  menu?: { items?: IMenuItems[] };
}

export default function Breadcrumb(props: IProps) {
  const { isMobile } = props;

  const [list, setList] = useState<IBreadcrumbItem[]>([]);
  const { menuInfo } = permissionStore((state) => ({
    menuInfo: state.menuInfo,
  }));

  const { isLayoutTop } = useThemeMenu();
  const location = useLocation();
  const { t } = useTranslation();

  const generatorList = useCallback(() => {
    const currentPath = location.pathname;
    const pathList = currentPath.split('/').filter(Boolean);
    let index = 0;
    const breadcrumbList = [] as IBreadcrumbItem[];

    const transformMenuToBreadcrumb = (menus: IMenuDataItem[]): IMenuItems[] => {
      const arr = [] as IMenuItems[];
      menus.forEach((item) => {
        const data = {
          label: <Link to={item.path}>{item.locale ? t(item.locale) : item.menuName}</Link>,
          key: item.path,
        } as IMenuItems;
        if (item.children?.length) {
          data.children = transformMenuToBreadcrumb(item.children);
        }
        arr.push(data);
      });
      return arr;
    };

    const findPath = (path: string, menuInfo?: IMenuDataItem[] | null) => {
      if (!menuInfo?.length) return;
      const target = menuInfo.find((item) => item.path.includes(path));
      if (target) {
        const breadcrumb = {} as IBreadcrumbItem;
        breadcrumb.title = (
          <span className="cursor-pointer text-sm">
            {target?.locale ? t(target.locale) : target.menuName}
          </span>
        );
        if (!target.children && index !== pathList.length - 1) {
          breadcrumb.href = target.path;
        }
        if (target.children) {
          breadcrumb.href = undefined;
          breadcrumb.menu = { items: transformMenuToBreadcrumb(target.children) };
        }

        breadcrumbList.push(breadcrumb);
        findPath(pathList[++index], target.children);
      }
    };
    findPath(pathList[index], menuInfo);
    setList(breadcrumbList);
  }, [location.pathname, menuInfo, t]);

  useEffect(() => {
    generatorList();
  }, [generatorList]);

  return isMobile || isLayoutTop ? null : (
    <AntdBreadcrumb className="ml-2" items={list}></AntdBreadcrumb>
  );
}
