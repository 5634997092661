import { StatusEnum } from 'src/api/types/common';
import {
  MenuTypeEnum,
  HttpStatusCodeEnum,
  SystemStatusCodeEnum,
  VisibleEnum,
  OpenPageTypeEnum,
  DataScopeEnum,
} from 'src/interface';
import { PageModeEnum } from 'src/interface/theme';
import { t } from 'src/locales';

export enum CurrentLoginTypeEnum {
  PHONE = 'phone',
  EMAIL = 'email',
  PASSWORD = 'password',
}

export const loginTypeOptions = [
  { label: 'password', value: CurrentLoginTypeEnum.PASSWORD },
  { label: 'phone', value: CurrentLoginTypeEnum.PHONE },
  { label: 'email', value: CurrentLoginTypeEnum.EMAIL },
];

export const SystemExceptionCodeToMessage = () => ({
  [SystemStatusCodeEnum.SUCCESS]: '',
  [HttpStatusCodeEnum.BAD_REQUEST]: t('request.exception.400'),
  [HttpStatusCodeEnum.UNAUTHORIZED]: t('request.exception.401'),
  [HttpStatusCodeEnum.FORBIDDEN]: t('request.exception.403'),
  [HttpStatusCodeEnum.NOT_FOUND]: t('request.exception.404'),
  [HttpStatusCodeEnum.REQUEST_TIMEOUT]: t('request.exception.408'),
  [HttpStatusCodeEnum.NOT_RESOURCE]: t('request.exception.410'),
  [HttpStatusCodeEnum.UNSUPPORTED_TYPE]: t('request.exception.415'),
  [HttpStatusCodeEnum.SERVER_ERROR]: t('request.exception.500'),
  [HttpStatusCodeEnum.NOT_IMPLEMENTED]: t('request.exception.501'),
  [HttpStatusCodeEnum.BAD_GATEWAY]: t('request.exception.502'),
  [HttpStatusCodeEnum.SERVER_UUNAVAILABLE]: t('request.exception.503'),
  [HttpStatusCodeEnum.GATEWAY_TIMEOUT]: t('request.exception.504'),
  [HttpStatusCodeEnum.HTTP_VERSION_NOT_SUPPORTED]: t('request.exception.505'),
  [SystemStatusCodeEnum.ERROR_PARAM]: t('request.system_exception.40000'),
  [SystemStatusCodeEnum.ERROR_UNAUTHORIZED]: t('request.system_exception.40001'),
  [SystemStatusCodeEnum.ERROR_PERMISSION]: t('request.system_exception.40002'),
  [SystemStatusCodeEnum.ERROR_DATA_NOT_EXIST]: t('request.system_exception.40003'),
  [SystemStatusCodeEnum.ERROR_API_NOT_EXIST]: t('request.system_exception.40004'),
  [SystemStatusCodeEnum.ERROR_NOT_SUPPORT_METHOD]: t('request.system_exception.40005'),
  [SystemStatusCodeEnum.ERROR_INVALID_URL]: t('request.system_exception.40006'),
  [SystemStatusCodeEnum.ERROR_DATA_REPEAT]: t('request.system_exception.40007'),
  [SystemStatusCodeEnum.ERROR_SYSTEM]: t('request.system_exception.50000'),
  [SystemStatusCodeEnum.ERROR_CODE_MAINTENANCE]: t('request.system_exception.50001'),
  [SystemStatusCodeEnum.ERROR_UNKNOWN]: t('request.system_exception.50002'),
  [SystemStatusCodeEnum.ERROR_INVALID_RESPONSE]: t('request.system_exception.50003'),
  [SystemStatusCodeEnum.ERROR_USE_EXCEPTION]: t('request.system_exception.50004'),
  [SystemStatusCodeEnum.ERROR_SERVER_INTERNAL]: t('request.system_exception.50100'),
  [SystemStatusCodeEnum.ERROR_SERVER_INTERNAL_TIMEOUT]: t('request.system_exception.50101'),
  [SystemStatusCodeEnum.ERROR_DB]: t('request.system_exception.60101'),
  [SystemStatusCodeEnum.ERROR_THIRD_HTTP]: t('request.system_exception.70101'),
});

export const PageModeStyleFilter = {
  [PageModeEnum.Normal]: 'none',
  [PageModeEnum.Weakness]: 'invert(80%)',
  [PageModeEnum.Grieve]: 'grayscale(1)',
};

export const MenuOptions = [
  { value: MenuTypeEnum.DIRE, label: '目录' },
  { value: MenuTypeEnum.MENU, label: '菜单' },
  { value: MenuTypeEnum.BUTTON, label: '按钮' },
];

export const VisibleOptions = [
  { value: VisibleEnum.HIDE, label: '隐藏' },
  { value: VisibleEnum.SHOW, label: '展示' },
];

export const OpenPageOptions = [
  { value: OpenPageTypeEnum.NEW_TAB, label: '页签' },
  { value: OpenPageTypeEnum.NEW_PAGE, label: '新窗口' },
];

// 1：全部数据权限 2：自定数据权限 3：本部门数据权限 4：本部门及以下数据权限
export const DataScopeOptions = [
  { value: DataScopeEnum.ALL, label: '全部数据权限' },
  { value: DataScopeEnum.CUSTOM, label: '自定数据权限' },
  { value: DataScopeEnum.DEPT, label: '本部门数据权限' },
  { value: DataScopeEnum.DEPT_AND_CHILD, label: '本部门及以下数据权限' },
  { value: DataScopeEnum.ONESELF, label: '仅自身数据权限' },
];

export const StatusOptions = [
  { value: StatusEnum.NORMAL, label: '展示' },
  { value: StatusEnum.STOP, label: '隐藏' },
];
