export enum StorageTypeEnum {
  LOCAL = 'local',
  SESSION = 'session',
}

export type OrderType = 'desc' | 'asc';

export interface OptionType {
  title: string;
  value: string;
  children?: OptionType[];
}

export enum CurrentLoginTypeEnum {
  PHONE = 'phone',
  EMAIL = 'email',
  PASSWORD = 'password',
}

export interface IHeaderTabItem {
  name?: string;
  path: string;
  locale?: string;
  icon?: string | React.ReactNode;
}

export enum VisibleEnum {
  SHOW = '0',
  HIDE = '1',
}

export enum OpenPageTypeEnum {
  NEW_TAB = '0',
  NEW_PAGE = '1',
}

export enum DataScopeEnum {
  ALL = '1',
  CUSTOM = '2',
  DEPT = '3',
  DEPT_AND_CHILD = '4',
  ONESELF = '5',
}

export interface ISelectTree {
  label: string;
  value: number;
  children?: ISelectTree[];
}
