interface Options {
  time?: number;
}
type Function = (...args: any[]) => any;

type Event = 'noUpdate' | 'update';

export class Updater {
  oldScripts: string[]; //保存当前 script 的 hash 数据
  newScripts: string[]; //保存最新 script 的 hash 数据
  dispatcher: Record<string, Function[]>; //发布订阅，通知用户有更新

  constructor(options: Options) {
    this.oldScripts = [];
    this.newScripts = [];
    this.dispatcher = {};
    this.init(); //初始化
    this.timing(options?.time); //轮询
  }

  async init() {
    const html: string = await this.getHtml();
    this.oldScripts = this.parserScript(html);
  }

  //读取index html
  async getHtml() {
    const html = await fetch('/').then((res) => res.text());
    return html;
  }

  //解析 script 标签
  parserScript(html: string) {
    const reg = new RegExp(/<script(?:\s+[^>]*)?>(.*?)<\/script\s*>/gi); //script正则
    return html.match(reg) as string[]; //匹配script标签
  }

  //发布订阅通知
  on(event: Event, fn: Function) {
    if (!this.dispatcher[event]) {
      this.dispatcher[event] = [];
    }
    this.dispatcher[event].push(fn);
    return this;
  }
  once(event: Event, fn: Function) {
    //为事件注册单次监听器
    const wrapFanc = (...args: any[]) => {
      fn.apply(this, args);
      this.off(event, wrapFanc);
    };
    this.on(event, wrapFanc);
    return this;
  }
  off(event: Event, fn: Function) {
    //停止监听event事件
    const callbacks = this.dispatcher[event];
    this.dispatcher[event] = callbacks?.filter((f) => f !== fn);
    return this;
  }

  compare(oldArr: string[], newArr: string[]) {
    const base = oldArr.length;
    const arr = Array.from(new Set(oldArr.concat(newArr)));
    //新旧 length 一样就是没有更新
    if (arr.length === base) {
      this.dispatcher['noUpdate']?.forEach((fn) => {
        fn();
      });
    }
    //否则通知更新
    else {
      this.dispatcher['update']?.forEach((fn) => {
        fn();
        this.dispatcher['update'] = [];
      });
    }
  }

  timing(time = 15000) {
    //轮询
    setInterval(async () => {
      const newHtml = await this.getHtml();
      this.newScripts = this.parserScript(newHtml);
      this.compare(this.oldScripts, this.newScripts);
    }, time);
  }
}
