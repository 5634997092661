import * as AntdIcon from 'react-icons/ai';

interface IconProps {
  name: string;
  size?: number;
  className?: string;
  style?: React.CSSProperties;
}

export const Icon = (props: IconProps) => {
  const { name, size = 18, ...rest } = props || {};

  const IconEl = (AntdIcon as any)?.[`${name}`];

  if (!IconEl) {
    return null;
  }

  return (
    <span className="anticon">
      <IconEl className="mr-[4px]" size={size} {...rest} />
    </span>
  );
};

interface IconPanelProps {
  onSelect?: (icon: string) => void;
}

export const IconPanel = (props: IconPanelProps) => {
  const { onSelect } = props;
  return (
    <div className="flex flex-wrap justify-start items-center w-[250px] h-[200px] overflow-y-auto overflow-x-hidden">
      {Object.keys(AntdIcon).map((name) => {
        return (
          <div
            key={name}
            onClick={() => {
              onSelect?.(name);
            }}
            className="w-1/6 h-[30px] flex-2 cursor-pointer flex justify-center items-center">
            <Icon name={name} size={20} />
          </div>
        );
      })}
    </div>
  );
};
