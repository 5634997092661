import request from 'src/api/http';
import { ICaptchaResp, ILoginReq, IPersonalInfoResp } from '../types/login';

export const getCaptcha = () => {
  return request.get<ICaptchaResp>('/captcha');
};

export const login = (data: ILoginReq) => {
  return request.post<string>('/login', {
    data,
  });
};

export const getRoutes = () => {
  return request.get('/routers');
};

export const getPersonalInfo = () => {
  return request.get<IPersonalInfoResp>('/user/personal');
};

export const logout = () => {
  return request.post('/logout');
};
