import { Tooltip } from 'antd';
import { AiOutlineSetting } from 'react-icons/ai';
import { useTranslation } from 'react-i18next';
import SettingDrawer from '../../SettingDrawer';
import { useBoolean } from 'ahooks';

interface IProps {
  className?: string;
}

export default function ScreenFull(props: IProps) {
  const { className } = props;
  const [open, { setFalse, setTrue }] = useBoolean(false);
  const { t } = useTranslation();

  function visibleModal() {
    setTrue();
  }

  return (
    <>
      <Tooltip title={t('main.header.setting')}>
        <div className={className} onClick={visibleModal}>
          <AiOutlineSetting size={20} />
        </div>
      </Tooltip>
      <SettingDrawer open={open} onClose={setFalse} />
    </>
  );
}
