import { ScreenEnum, ScreenTypeEnum } from 'src/interface/theme';

export const getScreenSizeType = (width?: number): ScreenTypeEnum => {
  const screenWidth = width ?? window.innerWidth;
  if (screenWidth < ScreenEnum.XS) {
    // 应用超小屏幕样式
    return ScreenTypeEnum.XS;
  } else if (screenWidth > ScreenEnum.XS && screenWidth <= ScreenEnum.SM) {
    // 应用小屏幕样式
    return ScreenTypeEnum.SM;
  } else if (screenWidth > ScreenEnum.SM && screenWidth <= ScreenEnum.MD) {
    // 应用中等屏幕样式
    return ScreenTypeEnum.MD;
  } else if (screenWidth > ScreenEnum.MD && screenWidth <= ScreenEnum.LG) {
    // 应用超大屏幕样式
    return ScreenTypeEnum.LG;
  } else if (screenWidth > ScreenEnum.LG && screenWidth <= ScreenEnum.XL) {
    // 应用超大屏幕样式
    return ScreenTypeEnum.XL;
  } else {
    // 应用超大屏幕样式 (2xl)
    return ScreenTypeEnum.XXL;
  }
};
