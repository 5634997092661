import { ColorPicker, InputNumber, Slider, Switch } from 'antd';
import { useTranslation } from 'react-i18next';
import { themeStore, AntdTheme } from 'src/stores/theme';
import { Color } from 'antd/es/color-picker';

export default function ThemeStyle() {
  const {
    colorPrimary,
    colorSuccess,
    colorWarning,
    colorError,
    fontSize,
    borderRadius,
    wireframe,
    setThemeConfig,
  } = themeStore((state) => ({
    ...state.theme.theme,
    setThemeConfig: state.setTheme,
  }));
  const { t } = useTranslation();

  const onChange = (value: Partial<AntdTheme>) => {
    setThemeConfig?.(value);
  };

  return (
    <div className="mt-[10px]">
      <div className="flex justify-between items-center my-[10px]">
        <div>{t('main.setting.primary_color')}</div>
        <ColorPicker
          onChange={(value: Color) => onChange({ colorPrimary: value.toHexString() })}
          defaultValue={colorPrimary}
          showText
          value={colorPrimary}
        />
      </div>
      <div className="flex justify-between items-center my-[10px]">
        <div>{t('main.setting.success_color')}</div>
        <ColorPicker
          onChange={(value: Color) => onChange({ colorSuccess: value.toHexString() })}
          defaultValue={colorSuccess}
          showText
          value={colorSuccess}
        />
      </div>
      <div className="flex justify-between items-center my-[10px]">
        <div>{t('main.setting.error_color')}</div>
        <ColorPicker
          onChange={(value: Color) => onChange({ colorError: value.toHexString() })}
          defaultValue={colorError}
          showText
          value={colorError}
        />
      </div>
      <div className="flex justify-between items-center my-[10px]">
        <div>{t('main.setting.warning_color')}</div>
        <ColorPicker
          onChange={(value: Color) => onChange({ colorWarning: value.toHexString() })}
          defaultValue={colorWarning}
          showText
          value={colorWarning}
        />
      </div>
      <div className="flex justify-between items-center my-[10px]">
        <div>{t('main.setting.font_size')}</div>
        <div className="flex justify-end items-center flex-none w-[60%]">
          <Slider
            min={12}
            max={32}
            onChange={(e) => onChange({ fontSize: e })}
            value={typeof fontSize === 'number' ? fontSize : Number(fontSize)}
            className="w-[60%] max-w-[100px] flex-none"
          />
          <InputNumber
            min={12}
            max={32}
            style={{ marginLeft: '16px' }}
            value={fontSize}
            onChange={(e) => onChange({ fontSize: Number(e) })}
          />
        </div>
      </div>
      <div className="flex justify-between items-center my-[10px]">
        <div>{t('main.setting.border_radius')}</div>
        <div className="flex justify-end items-center flex-none w-[60%]">
          <Slider
            min={0}
            max={16}
            onChange={(e) => onChange({ borderRadius: e })}
            value={typeof borderRadius === 'number' ? borderRadius : Number(borderRadius)}
            className="w-[60%] max-w-[100px] flex-none"
          />
          <InputNumber
            min={0}
            max={16}
            style={{ marginLeft: '16px' }}
            value={borderRadius}
            onChange={(e) => onChange({ borderRadius: Number(e) })}
          />
        </div>
      </div>
      <div className="flex justify-between items-center my-[10px]">
        <div>{t('main.setting.wireframe')}</div>
        <Switch checked={wireframe} onChange={(e) => onChange({ wireframe: e })}></Switch>
      </div>
    </div>
  );
}
