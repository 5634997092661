import { useMemo } from 'react';
import { useThemeMenu } from './useThemeMenu';
import { themeStore } from 'src/stores/theme';
// 处理侧导主题
export function useThemeHeader() {
  const { isLayoutTop, isLayoutSider } = useThemeMenu();
  const { header, root } = themeStore((state) => ({
    header: state.theme.header,
    root: state.theme.root,
  }));
  const { show, fixed } = header;
  const { showLogo } = root;
  // 显示mix模式的header
  const showMixHeader = useMemo(() => {
    return !isLayoutSider && show;
  }, [show, isLayoutSider]);

  // 显示内联的header
  const showInsetHeader = useMemo(() => {
    return show && (isLayoutTop || !showMixHeader);
  }, [show, showMixHeader, isLayoutTop]);

  const showOuterHeader = useMemo(() => {
    return show && !isLayoutTop && showMixHeader;
  }, [show, isLayoutTop, showMixHeader]);

  const showLogoHeader = useMemo(() => {
    return showLogo && !isLayoutSider;
  }, [showLogo, isLayoutSider]);

  return {
    show,
    fixed,
    showMixHeader,
    showInsetHeader,
    showOuterHeader,
    showLogoHeader,
  };
}
