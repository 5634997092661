import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { appLanguage } from 'src/constants';
// 导入语言包
import lange_cn from './zh_CN';
import lange_en from './en_US';

export enum LanguageEnum {
  EN = 'en',
  CN = 'cn',
}

export const NsMapping = {
  [LanguageEnum.EN]: 'en_US',
  [LanguageEnum.CN]: 'zh_CN',
} as Record<string, string>;

export const LanguageNameMap = {
  [LanguageEnum.EN]: 'English',
  [LanguageEnum.CN]: '中文',
} as Record<string, string>;

const resources = {
  [LanguageEnum.CN]: {
    translation: lange_cn,
  },
  [LanguageEnum.EN]: {
    translation: lange_en,
  },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: LanguageEnum.CN,
    debug: isDEV,
    saveMissing: false,
    ns: ['translation'],
    defaultNS: 'translation',
    keySeparator: '.',
    interpolation: {
      escapeValue: false,
    },
    detection: {
      order: ['htmlTag', 'cookie', 'localStorage', 'sessionStorage'],
      lookupCookie: appLanguage,
      lookupLocalStorage: appLanguage,
      lookupSessionStorage: appLanguage,
      lookupFromPathIndex: 0,
      lookupFromSubdomainIndex: 0,
      caches: ['localStorage'],
      htmlTag: document.head,
    },
  });
i18n.on('languageChanged', () => {
  let lang = i18n.language;
  if (lang === LanguageEnum.CN) {
    lang = 'zh-CN';
  }
  document.documentElement.lang = lang;
});

export const t = i18n.t.bind(i18n);
export default i18n;
