import { OrderType } from './system';

export enum HttpStatusCodeEnum {
  /**
   * 操作成功
   */
  SUCCESS = 200,
  /**
   * 对象创建成功
   */
  CREATED = 201,
  /**
   * 请求已经被接受
   */
  ACCEPTED = 202,
  /**
   * 服务器成功处理了请求，没有返回任何内容。
   */
  NO_CONTENT = 204,
  /**
   * 服务器成功处理了请求，但没有返回任何内容。与204响应不同，此响应要求请求者重置文档视图
   */
  RESET_CONTENT = 205,
  /**
   * 资源永久重定向
   */
  MOVED_PERMANENTLY = 301,
  /**
   * 资源临时重定向
   */
  FOUND = 302,
  /**
   * 当响应于POST（或PUT / DELETE）接收到响应时，客户端应该假定服务器已经收到数据，并且应该使用单独的GET消息发出重定向
   */
  SEE_OTHER = 303,
  /**
   * 资源没有被修改，因为请求头指定的版本If-Modified-Since或If-None-Match
   */
  NOT_MODIFIED = 304,
  /**
   * 参数列表错误（缺少，格式不匹配）
   */
  BAD_REQUEST = 400,
  /**
   * 未授权
   */
  UNAUTHORIZED = 401,
  /**
   * 访问受限，授权过期
   */
  FORBIDDEN = 403,
  /**
   * 资源，服务未找到
   */
  NOT_FOUND = 404,
  /**
   * 不允许的http方法
   */
  METHOD_NOT_ALLOWED = 405,
  /**
   * 请求超时
   */
  REQUEST_TIMEOUT = 408,
  /**
   * 资源冲突，或者资源被锁
   */
  CONFLICT = 409,
  /**
   * 请求资源不存在，并且永久丢失了
   */
  NOT_RESOURCE = 410,
  /**
   * 不支持的数据，媒体类型
   */
  UNSUPPORTED_TYPE = 415,
  /**
   * 禁止访问
   */
  NO_RESPONSE = 444,
  /**
   * 系统内部错误
   */
  SERVER_ERROR = 500,
  /**
   * 接口未实现
   */
  NOT_IMPLEMENTED = 501,
  /**
   * 服务不可用，过载或者维护
   */
  BAD_GATEWAY = 502,
  /**
   * 服务器错误
   */
  SERVER_UUNAVAILABLE = 503,
  /**
   * 网关超时
   */
  GATEWAY_TIMEOUT = 504,
  /**
   * 暂不支持当前http版本
   */
  HTTP_VERSION_NOT_SUPPORTED = 505,
  /**
   * 未知错误
   */
  UNKNOWN_ERROR = 520,
  /**
   * 服务未知错误
   */
  SERVICE_ERROR = 521,
  /**
   * 数据库未知错误
   */
  DATABASE_ERROR = 522,
  /**
   * 系统警告消息
   */
  SYSTEM_WARN = 601,
}

export enum SystemStatusCodeEnum {
  SUCCESS = 0,
  // 请求参数不正确，请检查后重试
  ERROR_PARAM = 40000,
  // 当前身份已失效，请重新登录
  ERROR_UNAUTHORIZED,
  // 您不具备该权限，请申请权限
  ERROR_PERMISSION,
  // 数据不存在
  ERROR_DATA_NOT_EXIST,
  // 接口不存在
  ERROR_API_NOT_EXIST,
  // 不支持的方法，仅支持GET、POST、PUT、DELETE、HEAD
  ERROR_NOT_SUPPORT_METHOD,
  // 非法URL
  ERROR_INVALID_URL,
  // 数据重复
  ERROR_DATA_REPEAT,
  // 系统错误
  ERROR_SYSTEM = 50000,
  // 系统维护
  ERROR_CODE_MAINTENANCE,
  // 未知错误
  ERROR_UNKNOWN,
  // 数据错误
  ERROR_INVALID_RESPONSE,
  // 账户获取异常
  ERROR_USE_EXCEPTION,
  // 服务器内部错误
  ERROR_SERVER_INTERNAL = 50100,
  // 网络超时，请稍后重试
  ERROR_SERVER_INTERNAL_TIMEOUT,
  // 数据库错误
  ERROR_DB = 60101,
  // 第三方http错误
  ERROR_THIRD_HTTP = 70101,
}

export interface IBaseTableResonseExtraData {
  createBy?: string;
  createTime?: string;
  updateBy?: string;
  updateTime?: string;
  delFlag?: number;
}

export interface IBaseTableRequestParams {
  current?: number;
  size?: number;
  sort?: string;
  order?: OrderType;
}

export interface IBaseTableResonseWrap<T> {
  records: T[];
  total: number;
  size: number;
  current: number;
  pages: number;
}
