import { IMenuDataItem, RouteObject } from 'src/interface';

export const generateMenuTree = (menuArray: IMenuDataItem[], parentId = 0) => {
  const menuTree = [] as IMenuDataItem[];

  menuArray.forEach((menuItem) => {
    if (menuItem.parentId === parentId) {
      const children = generateMenuTree(menuArray, menuItem.menuId);
      if (children.length) {
        menuItem.children = children;
      }
      menuTree.push(menuItem);
    }
  });

  return menuTree;
};

export const flatRoutes = (routeList: RouteObject[]): RouteObject[] => {
  const newRoutesList = [] as RouteObject[];
  for (const menu of routeList) {
    if (!menu.children) {
      newRoutesList.push(menu);
    } else {
      menu.children.forEach((item) => {
        newRoutesList.push(item);
      });
    }
  }
  return newRoutesList;
};

export function numberGrow(ele: HTMLElement, num: number, start = 0, duration = 1000) {
  if (!ele) return;
  // 已加减号开头，存在多个数字 可能存在点后多个数字
  if (!/^(\+|-)?(\d+)(\.\d+)?$/.test(String(num))) {
    ele.innerHTML = `${num}`;
    return;
  }
  let startNum = 0;
  let timer: null | NodeJS.Timeout;
  const step = Math.floor((num * 20) / duration) ? Math.floor((num * 20) / duration) : num;

  timer = setInterval(() => {
    startNum = start + step;
    if (startNum >= num) {
      if (timer) {
        clearInterval(timer);
      }
      startNum = num;
      timer = null;
    } else {
      start = startNum;
    }
    ele.innerHTML = String(startNum);
  }, 20);
}

export function routerToMenuList(routeList: RouteObject[], parentPath?: string): IMenuDataItem[] {
  if (!routeList) return [];
  const path = parentPath ?? '';
  const menuList = [] as IMenuDataItem[];
  for (const route of routeList) {
    let menu = null as IMenuDataItem[] | null;
    if (route.children && route.children.length > 0) {
      menu = routerToMenuList(route.children, route.path);
    }
    const menuItem = {
      path: route.path?.startsWith('/') ? path + route.path : path + '/' + route.path,
      name: route.name,
      locale: route?.data?.locale,
      icon: route?.icon,
    } as IMenuDataItem;
    if (menu) menuItem.children = menu;
    menuList.push(menuItem);
  }
  return menuList;
}
