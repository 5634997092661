export enum LayoutTypeEnum {
  // 顶部侧导布局
  Mix = 'mix',
  // 顶部菜单
  Top = 'top',
  // 侧导顶部布局
  Sider = 'sider',
}

export enum MenuModeEnum {
  Vertical = 'vertical', // 垂直模式
  Horizontal = 'horizontal', // 水平模式
  Inline = 'inline', // 内嵌模式
}

// 主题
export enum ThemeEnum {
  Dark = 'dark',
  Light = 'light',
}

export enum PageModeEnum {
  Normal = 'normal',
  Weakness = 'weakness',
  Grieve = 'grieve',
}

// 窗口相关
export enum ScreenTypeEnum {
  XS = 'xs',
  SM = 'sm',
  MD = 'md',
  LG = 'lg',
  XL = 'xl',
  XXL = 'xxl',
}

export enum ScreenEnum {
  XS = 480,
  SM = 576,
  MD = 768,
  LG = 992,
  XL = 1200,
  XXL = 1600,
}

export enum TabsActionEnum {
  CloseAll,
  CloseOther,
  CloseLeft,
  CloseRight,
  CloseCurrent,
}
