import { useEffect, useMemo, useState, useRef } from 'react';
import { Layout as AntdLayout } from 'antd';
import cls from 'clsx';
import { prefixCls } from 'src/constants';
import { themeStore } from 'src/stores';
import { ContentProvider } from 'src/context/ContentContext';
import { LayoutTypeEnum } from 'src/interface/theme';
import { useBreakpoint, useThemeHeader, useThemeMenu } from 'src/hooks/theme';
import SiderMenu from './components/Sider';
import Header from './components/Header';
import Footer from './components/Footer';
import HeaderTabs from './components/HeaderTabs';
import RouteElement from './components/Content';

const Content = AntdLayout.Content;

export default function Layout() {
  const { layoutMode } = themeStore((state) => state.theme);
  const colSize = useBreakpoint();
  const { showSider, remainingWidth } = useThemeMenu();
  const { showOuterHeader } = useThemeHeader();
  const contentRef = useRef<HTMLDivElement | null>(null);
  const resizeObserver = useRef<ResizeObserver>();
  const [contentSize, setContentSize] = useState(0);

  const globalStyle: React.CSSProperties = {
    width: '100vw',
    height: '100vh',
    overflow: 'hidden',
  };

  const isMobile = useMemo(() => {
    return colSize === 'sm' || colSize === 'xs';
  }, [colSize]);

  const hasMenuCls = useMemo(() => {
    if (layoutMode === LayoutTypeEnum.Mix || layoutMode === LayoutTypeEnum.Sider) {
      return prefixCls + '-layout-has-sider';
    }
    return '';
  }, [layoutMode]);

  const contentCls = useMemo(() => {
    const layout = [LayoutTypeEnum.Top];
    if (layout.includes(layoutMode)) {
      return 'overflow-y-auto overflow-x-hidden';
    }
    return 'overflow-y-auto overflow-x-hidden';
  }, [layoutMode]);

  const renderSiderMenu = useMemo(() => {
    if (showSider || isMobile) {
      return <SiderMenu isMobile={isMobile} />;
    }

    return null;
  }, [showSider, isMobile]);

  useEffect(() => {
    if (contentRef.current && !resizeObserver.current) {
      const observer = new ResizeObserver((e) => {
        setContentSize(e[0].contentRect.width);
      });
      resizeObserver.current = observer;
      if (contentRef.current) observer.observe(contentRef.current);
    }
    return () => {
      if (resizeObserver.current) resizeObserver.current.disconnect();
    };
  }, []);

  return (
    <AntdLayout style={globalStyle}>
      {showOuterHeader && <Header fixed isMobile={isMobile} />}
      <AntdLayout className={cls(hasMenuCls, prefixCls + '-layout')}>
        {renderSiderMenu}
        <AntdLayout>
          <HeaderTabs isMobile={isMobile} remainingWidth={remainingWidth} />
          <Content ref={contentRef} className={cls(contentCls, 'w-[100%] h-[100%]')}>
            <ContentProvider value={contentSize}>
              <RouteElement />
            </ContentProvider>
          </Content>
          <Footer />
        </AntdLayout>
      </AntdLayout>
    </AntdLayout>
  );
}
