import { isValidElement } from 'react';
import { Navigate } from 'react-router-dom';
import { RouteObject } from 'src/interface/route';

const routeFiles = import.meta.webpackContext('./routes', {
  regExp: /\.(tsx|ts)$/,
  recursive: true,
});

export const permissionRoute = [] as RouteObject[];
export const basicRoute = [] as RouteObject[];

const analysisFile = () => {
  routeFiles.keys().map((item) => {
    const routeList = (routeFiles(item) as { default: RouteObject[] }).default;
    if (item.includes('basic') || item.includes('static')) {
      basicRoute.push(...routeList.map(analysisRoute));
    } else {
      permissionRoute.push(...routeList.map(analysisRoute));
    }
  });
};

export const analysisRoute = (routeItem: RouteObject): RouteObject => {
  if (routeItem.redirect) {
    return {
      path: routeItem.path,
      element: <Navigate to={routeItem.redirect}></Navigate>,
    };
  }
  if (isValidElement(routeItem.element)) {
    return {
      path: routeItem.path,
      element: routeItem.element,
      data: routeItem.data,
      children: routeItem.children?.map(analysisRoute),
    };
  }
  return {
    path: routeItem.path,
    element: <Navigate to="/"></Navigate>,
  };
};

const createRoute = () => {
  analysisFile();

  const routeConfig: RouteObject[] = [
    ...basicRoute,
    {
      path: '/:pathMatch(.*)*',
      element: <Navigate to="/error/404" />,
    },
  ];
  return routeConfig;
};

export const routeConfig = createRoute();
