import { Drawer } from 'antd';
import { useThemeMenu } from 'src/hooks/theme';
import Sider from './BasicSider';

interface IProp {
  styles?: {
    header?: React.CSSProperties;
    body?: React.CSSProperties;
    footer?: React.CSSProperties;
    content?: React.CSSProperties;
    mask?: React.CSSProperties;
    wrapper?: React.CSSProperties;
  };
  isMobile?: boolean;
}

export default function LayoutSider(props: IProp) {
  const { styles, isMobile } = props;
  const { menuWidth, traggetCollapsed, collapsed } = useThemeMenu();

  return isMobile ? (
    <Drawer
      placement="left"
      title={null}
      onClose={() => traggetCollapsed(true)}
      styles={{
        content: {
          padding: 0,
          height: '100vh',
        },
        body: {
          padding: 0,
          display: 'flex',
          flexDirection: 'row',
        },
        header: {
          border: 'none',
        },
        ...styles,
      }}
      width={menuWidth}
      open={!collapsed}>
      <Sider isMobile={isMobile} isDrawer={true} />
    </Drawer>
  ) : (
    <Sider isMobile={isMobile} />
  );
}
