import { useEffect, useMemo, useState } from 'react';
import { Divider, Button } from 'antd';
import clsx from 'clsx';
import { useNavigate, useLocation } from 'react-router-dom';
import { AiOutlineGithub, AiOutlineAlipay, AiOutlineAliyun } from 'react-icons/ai';
import { useTranslation } from 'react-i18next';
import { prefixCls, loginTypeOptions } from 'src/constants';
import login from 'src/assets/icons/login_left.svg';
import LogoIcon from 'src/layout/components/LogoIcon';
import DarkSwitch from 'src/components/DarkSwitch';
import Language from 'src/components/Language';
import SignUpForm from './components/SignupForm';
import './index.less';
import LoginForm from './components/PassForm';

enum LoginType {
  Login,
  SignUp,
}

const baseCls = prefixCls + '-login';
export default function Login() {
  const [type, setType] = useState<LoginType>(LoginType.Login);
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  const options = loginTypeOptions.map((item) => {
    return {
      label: t(`login.type.${item.value}`),
      value: item.value,
    };
  });

  const changeType = (type: LoginType) => {
    setType(type);
  };

  const render = useMemo(() => {
    if (type === LoginType.Login) {
      return (
        <>
          <LoginForm />
          <Divider plain className="my-9">
            {t('login.other_methods')}
          </Divider>
          <div className="flex justify-around items-center">
            <AiOutlineGithub
              size={32}
              className="cursor-pointer dark:text-gray-200 text-gray-500 hover:dark:text-black hover:text-black"
            />
            <AiOutlineAlipay
              size={32}
              className="cursor-pointer dark:text-gray-200 text-gray-500 hover:dark:text-[#1378FE] hover:text-[#1378FE]"
            />
            <AiOutlineAliyun
              size={32}
              className="cursor-pointer dark:text-gray-200 text-gray-500 hover:dark:text-[#FF7826] hover:text-[#FF7826]"
            />
          </div>
        </>
      );
    } else {
      return <SignUpForm />;
    }
  }, [type, options, t]);

  useEffect(() => {
    if (location.search) {
      // const params = new URLSearchParams(location.search);
      // params.get('redirect') && setRedirect(params.get('redirect') as string);
    }
  }, [location.search, navigate]);

  return (
    <div className={clsx(baseCls)}>
      <div className="absolute top-2 right-2 flex items-center">
        <Language className="mr-2 text-gray-500 dark:text-gray-300" />
        <DarkSwitch />
      </div>
      <div className="hidden xl:w-1/3 lg:w-1/2 lg:flex flex-col justify-center items-center h-full py-4 dark:bg-gray-900">
        <div className="animate__animated animate__slideInDown">
          <LogoIcon isShowText={true} style={{ height: 120 }} textStyle={{ fontSize: 32 }} />
        </div>
        <div className="text-xl px-3">{t('login.descript')}</div>
        <img src={login} className="h-[50%] w-[60%]" />
      </div>
      <div className="lg:w-2/3 w-full flex justify-center items-center bg-white dark:bg-gray-700">
        <div className="xxl:w-1/3 xl:w-1/2 lg:w-2/3 w-full lg:max-w-[400px] xl:max-w-[100%] p-8 flex flex-col justify-center items-center rounded-md animate__animated animate__fadeInRight">
          <div className="max-w-[350px] lg:max-w-[100%] xl:max-w-[100%] mb-3 w-[100%] my-4">
            <div className="text-2xl mb-2">
              {type === LoginType.Login ? t(`login.type.password`) : t('login.signup')}
            </div>
            <div className="text-sm mb-4">
              {type === LoginType.Login ? (
                <>
                  {t('login.no_account')}？
                  <Button type="link" onClick={() => changeType(LoginType.SignUp)}>
                    {t('login.go_signup')}
                  </Button>
                </>
              ) : (
                <>
                  {t('login.account_exists')}？
                  <Button type="link" onClick={() => changeType(LoginType.Login)}>
                    {t('login.go_login')}
                  </Button>
                </>
              )}
            </div>
            {render}
          </div>
        </div>
      </div>
    </div>
  );
}
