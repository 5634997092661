/* eslint-disable react-refresh/only-export-components */
import loadable from '@loadable/component';
import { RouteObject } from 'src/interface/route';
import Layout from 'src/layout';

const UsersManage = loadable(() => import('src/pages/system/users'));
const MenuManage = loadable(() => import('src/pages/system/menus'));
const RoleManage = loadable(() => import('src/pages/system/roles'));
const PostManage = loadable(() => import('src/pages/system/posts'));
const DeptManage = loadable(() => import('src/pages/system/depts'));
const DictManage = loadable(() => import('src/pages/system/dicts'));

const systemRoute: RouteObject[] = [
  {
    path: '/system',
    element: <Layout />,
    name: '系统管理',
    data: {
      locale: 'menu.system',
    },
    children: [
      {
        path: 'users',
        name: '用户管理',
        data: {
          locale: 'menu.user',
        },
        element: <UsersManage />,
      },
      {
        path: 'roles',
        name: '角色管理',
        data: {
          locale: 'menu.role',
        },
        element: <RoleManage />,
      },
      {
        path: 'menus',
        name: '菜单管理',
        data: {
          locale: 'menu.menu',
        },
        element: <MenuManage />,
      },
      {
        path: 'depts',
        name: '部门管理',
        data: {
          locale: 'menu.dept',
        },
        element: <DeptManage />,
      },
      {
        path: 'posts',
        name: '岗位管理',
        data: {
          locale: 'menu.post',
        },
        element: <PostManage />,
      },
      {
        path: 'dicts',
        name: '字典管理',
        data: {
          locale: 'menu.dict',
        },
        element: <DictManage />,
      },
    ],
  },
];

export default systemRoute;
