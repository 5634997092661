import { create } from 'zustand';
import { IHeaderTabItem } from 'src/interface';
import { cloneDeep } from 'lodash-es';

export interface ITabStore {
  tabList: IHeaderTabItem[];
  addTabs: (tab: IHeaderTabItem) => void;
  removeTabs: (current: string) => void;
  setTabs: (tabs: IHeaderTabItem[]) => void;
}

export const tabStore = create<ITabStore>((set, get) => ({
  tabList: [],
  addTabs: (tab: IHeaderTabItem) => {
    const tabList = cloneDeep(get().tabList);
    if (tabList.some((item) => item.path === tab.path)) return;
    tabList.push(tab);
    set({ tabList });
  },
  removeTabs: (current: string) => {
    const tabList = cloneDeep(get().tabList);
    const index = tabList.findIndex((item) => item.path === current);
    if (index !== -1) {
      tabList.splice(index, 1);
      set({ tabList });
    }
  },
  setTabs: (tabs: IHeaderTabItem[]) => {
    set({ tabList: tabs });
  },
}));
