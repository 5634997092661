import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';
import { useThemeMenu } from 'src/hooks/theme';

interface IProps {
  isShowText?: boolean;
  style?: React.CSSProperties;
  textStyle?: React.CSSProperties;
}

export default function LogoIcon(props: IProps) {
  const { isShowText = true, style, textStyle } = props;

  const { menuTheme } = useThemeMenu();

  const navigate = useNavigate();

  function goHome() {
    navigate('/');
  }

  const logoClass = clsx({
    'text-gray-800 dark:text-gray-100': menuTheme === 'light',
    'text-gray-100 dark:text-gray-100': menuTheme === 'dark',
  });

  return (
    <div
      className="flex justify-center items-center h-[64px] cursor-pointer"
      style={style}
      onClick={goHome}>
      <img src="/logo.svg" className="h-[100%]"></img>
      {isShowText && (
        <span
          className={clsx('text-xl ml-2 font-semibold whitespace-nowrap', logoClass)}
          style={textStyle}>
          Sloth Admin{' '}
        </span>
      )}
    </div>
  );
}
