import { useState } from 'react';
import { useRequest } from 'ahooks';
import { useNavigate } from 'react-router-dom';
import { Form, Input, Button, Checkbox, Spin, Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import { AiFillLock, AiOutlineUser, AiOutlineQrcode } from 'react-icons/ai';
import { getCaptcha } from 'src/api/clients';
import { userStore } from 'src/stores/user';
import { permissionStore } from 'src/stores/permission';

export default function LoginForm() {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const { login } = userStore();
  const navigate = useNavigate();
  const { defaultOpenPath } = permissionStore();
  const {
    data: captchaInfo,
    run,
    loading: captchaLoading,
  } = useRequest(async () => {
    const result = await getCaptcha();
    return result;
  });

  const onSubmit = async (values: any) => {
    setLoading(true);
    const { username, password, code } = values;
    try {
      const isLogin = await login({ username, password, code, uuid: captchaInfo?.uuid });
      if (!isLogin) return;
      navigate(defaultOpenPath);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
      run();
    }
  };
  return (
    <>
      <Form
        size={'large'}
        initialValues={{ remember: true, username: 'admin', password: '123456' }}
        onFinish={onSubmit}
        className="mt-4">
        <Form.Item
          name="username"
          rules={[{ required: true, message: t('login.user_placeholder') }]}>
          <Input prefix={<AiOutlineUser />} placeholder={t('login.user_placeholder')} />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[{ required: true, message: t('login.pass_placeholder') }]}>
          <Input
            prefix={<AiFillLock />}
            type="password"
            placeholder={t('login.pass_placeholder')}
          />
        </Form.Item>
        <Form.Item name="code">
          <Row className="w-full" gutter={16}>
            <Col span={17}>
              <Input
                prefix={<AiOutlineQrcode />}
                type="text"
                placeholder={t('login.code_placeholder')}
              />
            </Col>
            <Col span={7}>
              <Spin spinning={captchaLoading}>
                <img
                  className="w-[100px] h-[40px] cursor-pointer"
                  src={captchaInfo?.img}
                  onClick={run}
                />
              </Spin>
            </Col>
          </Row>
        </Form.Item>
        <Form.Item>
          <div className="flex justify-between">
            <Form.Item name="remember" valuePropName="checked" noStyle>
              <Checkbox>{t('login.remember')}</Checkbox>
            </Form.Item>
            <a className="login-form-forgot" href="">
              {t('login.forget')}
            </a>
          </div>
        </Form.Item>
        <Form.Item>
          <Button
            loading={loading}
            type="primary"
            block
            size="large"
            htmlType="submit"
            className="login-form-button">
            {t('login.signin')}
          </Button>
        </Form.Item>
      </Form>
    </>
  );
}
