import { Tooltip } from 'antd';
import { AiOutlineFullscreen, AiOutlineFullscreenExit } from 'react-icons/ai';
import { useTranslation } from 'react-i18next';
import { useFullscreen } from 'ahooks';

interface IProps {
  className: string;
}

export default function ScreenFull(props: IProps) {
  const { className } = props;
  const [isFull, { toggleFullscreen }] = useFullscreen(document.body);
  const { t } = useTranslation();

  function changeScreenFull() {
    toggleFullscreen();
  }
  return (
    <Tooltip title={t(isFull ? 'main.header.exit_full' : 'main.header.entry_full')}>
      <div className={className} onClick={changeScreenFull}>
        {isFull ? <AiOutlineFullscreenExit size={20} /> : <AiOutlineFullscreen size={20} />}
      </div>
    </Tooltip>
  );
}
